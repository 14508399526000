import { useStyles } from '@archipro-design/aria';
import { DesignBoardDrawerHeader } from '../right-side-drawer/DesignBoardRIghtSideDrawerHeader';
import { DesignBoardDrawerFooter } from '../right-side-drawer/DesignBoardRightSideDrawerFooter';
import * as S from '../right-side-drawer/RightSideNewBoarderDrawer.style';
import type { PinItemType } from '~/modules/design-board/type';
import { useSaveToBoardFetchers } from '~/modules/design-board/hook/use-save-to-board-fetchers';
import SaveToDesignBoardList from '~/modules/design-board/component/save-to-design-board/SaveToDesignBoardList';

interface SaveToBoardChooseLocationProps {
    selectedPinId: number;
    selectedPinType: PinItemType;
    urlSearchParams?: string;
    onClose: () => void;
    onClickCreateNewBoard: () => void;
    libraryItemIdOverride?: number;
    skipSinglePinSaving?: boolean;
    afterSkipSaving?: (
        boardId: number,
        title?: string,
        description?: string
    ) => void;
}

const SaveToBoardChooseLocation = ({
    selectedPinId,
    selectedPinType,
    urlSearchParams,
    onClose,
    onClickCreateNewBoard,
    libraryItemIdOverride,
    skipSinglePinSaving,
    afterSkipSaving,
}: SaveToBoardChooseLocationProps) => {
    const { css } = useStyles();
    const { onSaveFavouriteItem, localFavouritedItems } =
        useSaveToBoardFetchers({
            skipSinglePinSaving,
            afterSkipSaving,
            itemId: selectedPinId,
            itemType: selectedPinType,
            urlSearchParams,
            onSave: () => {
                onClose();
            },
            libraryItemIdOverride: libraryItemIdOverride,
        });

    const onClickCreateButton = () => {
        onClickCreateNewBoard();
    };

    const onClickSaveButton = (
        broadId: number,
        boardTitle: string,
        urlSegment: string
    ) => {
        onSaveFavouriteItem(undefined, broadId, boardTitle, urlSegment);
    };

    return (
        <div className={css(S.FormClass)}>
            <DesignBoardDrawerHeader
                onClose={onClose}
                headerTitle={'Choose location'}
            />
            <SaveToDesignBoardList
                onClickSaveButton={onClickSaveButton}
                inputLabel={'Save to existing board'}
                listButtonLabel={'SAVE'}
                favouritedItems={localFavouritedItems}
                itemId={selectedPinId}
                itemType={selectedPinType}
            />
            <DesignBoardDrawerFooter
                buttonPrimaryAction={onClickCreateButton}
                submitButtonLabel={'CREATE NEW BOARD'}
            />
        </div>
    );
};

export default SaveToBoardChooseLocation;
