import type {
    ChooseDesignBoardModalItemsProps,
    ChooseDesignBoardModalProps,
} from '@archipro-design/aria';
import type { DesignBoard } from 'generated/graphql';
import {
    USER_BOARD_LIST_STORAGE_KEY,
    USER_FAVOURITED_ITEM_STORAGE_KEY,
} from '../config/design-board-config';
import type { UserFavouritedItem } from '../type/design-board-types';
import { localStorage } from '~/modules/root/util/browser-storage';

export const isFavouritedItem = (
    itemId: UserFavouritedItem['itemId'],
    itemType: UserFavouritedItem['itemType'],
    favouritedItems: UserFavouritedItem[],
    boardId?: number
) => {
    return favouritedItems.some(
        (favouritedItem) =>
            favouritedItem.itemId === itemId &&
            favouritedItem.itemType === itemType &&
            (boardId ? favouritedItem.boardId === boardId : true)
    );
};

export const getFormattedBoardData = (
    originData: DesignBoard[],
    itemId: UserFavouritedItem['itemId'],
    itemType: UserFavouritedItem['itemType']
): ChooseDesignBoardModalProps['designBoards'] => {
    return originData.map((board) => {
        const isSaved = board.Pins.some((pin) => {
            let isSameId = false;
            if (pin.__typename === 'MemberUploadPin') {
                isSameId = itemId === pin.TypeID;
            } else if (pin.__typename === 'PhotoPin') {
                isSameId = itemId === pin.LibraryImageID;
            } else {
                isSameId = itemId === pin.PageTypeID;
            }
            return pin.__typename === itemType && isSameId;
        });
        return {
            title: board.Title,
            id: board.ID,
            isPrivate: !board.Public,
            isSaved: isSaved,
        };
    });
};

export const getSavedBoardDataOfItem = (
    formattedBoard: ChooseDesignBoardModalProps['designBoards'],
    itemId: UserFavouritedItem['itemId'],
    itemType: UserFavouritedItem['itemType'],
    favouritedItems: UserFavouritedItem[]
): ChooseDesignBoardModalProps['designBoards'] => {
    return formattedBoard.map((board) => {
        if (typeof board === 'object' && board !== null && 'id' in board) {
            const isSaved = favouritedItems.some(
                (favouritedItem) =>
                    favouritedItem.itemId === itemId &&
                    favouritedItem.itemType === itemType &&
                    favouritedItem.boardId === board.id
            );
            return { ...board, isSaved: isSaved };
        }
        return board;
    });
};

export const getUserFavouritedItems = (
    originData: DesignBoard[]
): UserFavouritedItem[] => {
    const myFavouritedItems: UserFavouritedItem[] = [];

    originData.map((board) => {
        return board.Pins.map((pin) => {
            let savedItemId;
            if (pin.__typename === 'MemberUploadPin') {
                savedItemId = pin.TypeID;
            } else if (pin.__typename === 'PhotoPin') {
                savedItemId = pin.LibraryImageID;
            } else {
                savedItemId = pin.PageTypeID;
            }

            const item = {
                boardId: board.ID,
                itemId: savedItemId,
                itemType: pin.__typename,
                ID: pin.ID,
            } as UserFavouritedItem;

            myFavouritedItems.push(item);

            return item;
        });
    });

    return Array.from(new Set(myFavouritedItems));
};

export const findBoardById = (
    boardId: number,
    designBoardsData: ChooseDesignBoardModalProps['designBoards']
) => {
    return designBoardsData.find(
        (board) =>
            board &&
            typeof board === 'object' &&
            'id' in board &&
            board.id === boardId
    ) as ChooseDesignBoardModalItemsProps;
};

export const removeLocalFavouritedItems = () => {
    localStorage?.removeItem(USER_BOARD_LIST_STORAGE_KEY);
    localStorage?.removeItem(USER_FAVOURITED_ITEM_STORAGE_KEY);
};
