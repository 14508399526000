import { DesignBoardDrawerSelectPinsListItem } from '../right-side-drawer/DesignBoardDrawerSelectPinsListItem';
import { useState } from 'react';

import type {
    PinItemType,
    UserFavouritedItem,
} from '~/modules/design-board/type';
import { isFavouritedItem } from '~/modules/design-board/util';
import SearchDesignBoardList from '~/modules/design-board/component/right-side-drawer/SearchDesignBoardList';

interface BoardListItem {
    boardName: string;
    id: number;
    urlSegment: string;
}

interface SaveToDesignBoardListProps {
    onClickSaveButton: (
        boardId: number,
        boardTitle: string,
        urlSegment: string
    ) => void;
    inputLabel: string;
    listButtonLabel: string;
    favouritedItems: UserFavouritedItem[];
    itemId: number;
    itemType: PinItemType;
}

const SaveToDesignBoardList = ({
    onClickSaveButton,
    inputLabel,
    listButtonLabel,
    favouritedItems,
    itemId,
    itemType,
}: SaveToDesignBoardListProps) => {
    const [clickedId, setClickedId] = useState<number | null>(null);

    const onSavePin = (
        destinationBoardId: number,
        boardTitle: string,
        urlSegment: string
    ) => {
        setClickedId(destinationBoardId);
        onClickSaveButton(destinationBoardId, boardTitle, urlSegment);
    };

    const virtualListItemFn = (data: BoardListItem) => {
        const isFavourited = isFavouritedItem(
            itemId,
            itemType,
            favouritedItems,
            data?.id
        );

        return (
            <DesignBoardDrawerSelectPinsListItem
                clickedId={clickedId}
                key={`boardListItem${data?.id ?? ''}`}
                label={isFavourited ? 'SAVED' : listButtonLabel}
                {...data}
                onSave={(boardId, boardTitle, urlSegment) =>
                    onSavePin(boardId, boardTitle, urlSegment)
                }
                isFavourited={isFavourited}
            />
        );
    };

    return (
        <SearchDesignBoardList
            headerLabel={inputLabel}
            virtualListItemFn={virtualListItemFn}
        />
    );
};

export default SaveToDesignBoardList;
