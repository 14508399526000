import { useStyles } from '@archipro-design/aria';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type { SubmitHandler, FieldValues } from 'react-hook-form';
import { DesignBoardDrawerHeader } from '../right-side-drawer/DesignBoardRIghtSideDrawerHeader';
import { DesignBoardDrawerFooter } from '../right-side-drawer/DesignBoardRightSideDrawerFooter';
import NewDesignBoardDrawerContent from '../right-side-drawer/NewDesignBoardBody';
import * as S from '../right-side-drawer/RightSideNewBoarderDrawer.style';
import type { NewBoardFormShape } from '@modules/design-board/validation/DesignBoardDetailPageSchemas';
import { NewBoardValidator } from '@modules/design-board/validation/DesignBoardDetailPageSchemas';
import type { PinItemType } from '~/modules/design-board/type';
import { useSaveToBoardFetchers } from '~/modules/design-board/hook/use-save-to-board-fetchers';
import { useTracker } from '@archipro-website/tracker';

interface CreateNewBoardAndSavePinProps {
    selectedPinId: number;
    selectedPinType: PinItemType;
    urlSearchParams?: string;
    onClose: () => void;
    libraryItemIdOverride?: number;
    skipSinglePinSaving?: boolean;
    afterSkipSaving?: (
        boardId: number,
        title?: string,
        description?: string
    ) => void;
}

const CreateNewBoardAndSavePin = ({
    selectedPinId,
    selectedPinType,
    urlSearchParams,
    onClose,
    libraryItemIdOverride,
    skipSinglePinSaving,
    afterSkipSaving,
}: CreateNewBoardAndSavePinProps) => {
    const { createFetcher, onCreateNewBoardAndSave, isCreateBoardLoading } =
        useSaveToBoardFetchers({
            skipSinglePinSaving,
            afterSkipSaving,
            itemId: selectedPinId,
            itemType: selectedPinType,
            urlSearchParams,
            onSave: () => {
                onClose();
            },
            libraryItemIdOverride,
        });
    const tracker = useTracker();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<NewBoardFormShape>({
        resolver: zodResolver(NewBoardValidator),
        mode: 'onBlur',
    });

    const { css } = useStyles();

    const onSubmit: SubmitHandler<FieldValues> = (data) => {
        const { title, isPrivate, description = '' } = data;
        onCreateNewBoardAndSave({ title, isPrivate, description });
        tracker.log('DesignBoardCreateSubmit', {
            url: new URL(window.location.href),
        });
    };

    return (
        <>
            <createFetcher.Form
                className={css(S.FormClass)}
                onSubmit={handleSubmit(onSubmit)}
            >
                <DesignBoardDrawerHeader
                    {...{ onClose }}
                    headerTitle={'Create a board'}
                />

                <NewDesignBoardDrawerContent
                    errors={errors}
                    control={control}
                />

                <DesignBoardDrawerFooter
                    loading={isCreateBoardLoading}
                    submitButtonLabel="CREATE"
                />
            </createFetcher.Form>
        </>
    );
};

export default CreateNewBoardAndSavePin;
