import { useLocalStorageState } from 'ahooks';
import { useEffect } from 'react';
import { useUser } from '~/modules/user';

export const useRegisteredState = () => {
    const user = useUser();

    const [registered, setRegistered] = useLocalStorageState<boolean>(
        'registered-state',
        { defaultValue: false }
    );

    useEffect(() => {
        if (user.__typename !== 'Me') return;
        setRegistered(true);
    }, [user.__typename, setRegistered]);

    return { registered };
};
