import { useUser } from '~/modules/user';
import { useRootData } from './use-root-data';
import { useRegisteredState } from './use-registered-state';
import type { GrowthBookAppFeatures } from './use-growthbook';
import { useABTestIsOn } from './use-growthbook';

export const useAuthGating = (authGating: keyof GrowthBookAppFeatures) => {
    const flagOn = useABTestIsOn(authGating);
    const isGuest = useUser().__typename === 'Guest';
    const isBot = useRootData().device.isBot;
    const { registered } = useRegisteredState();
    return { enabled: flagOn && isGuest && !isBot && !registered };
};
