import type { PinItemType } from '../type';
import { useLocalStorageState } from 'ahooks';
import { useLocation } from '@remix-run/react';
import { useAuthGating } from '~/modules/root/hook/use-auth-gating';
import { useAuth } from '~/modules/root/context';

export const ADD_PIN_SEARCH_PARAM_KEY = 'addPin';
export const PIN_ITEM_LOCAL_KEY = 'saving-design-board-item';

export interface DesignBoardItem {
    itemId: number;
    itemType: PinItemType;
    urlSearchParams?: string;
    libraryItemIdOverride?: number;
}

export const useDesignBoardGating = () => {
    const { enabled: designBoardGatingEnabled } = useAuthGating(
        'design-board-gating'
    );

    const { pathname, search } = useLocation();

    const { showAuthModal: showAuthModalInternal } = useAuth();

    const [savingItem, setSavingItem] =
        useLocalStorageState<DesignBoardItem | null>(PIN_ITEM_LOCAL_KEY, {
            defaultValue: null,
        });

    const showAuthModal = (item: DesignBoardItem) => {
        setSavingItem(item);
        showAuthModalInternal({
            authSource: 'designBoardGate',
            redirect: appendAddPinToURL(`${pathname}${search}`),
            skipPostSignUp: true,
        });
    };

    return {
        designBoardGatingEnabled,
        showAuthModal,
        savingItem,
    };
};

const appendAddPinToURL = (url: string): string => {
    const [basePath, searchParamsStr] = decodeURI(url).split('?');
    const searchParams = new URLSearchParams(searchParamsStr);
    searchParams.set(ADD_PIN_SEARCH_PARAM_KEY, '1');
    const newSearchParamsStr = searchParams.toString();
    return `${basePath}?${newSearchParamsStr}`;
};
