import type { StyleRule } from '@archipro-design/aria';
import {
    chooseDesignBoardModalItemsSlotClassNames,
    flexClassName,
    pxArrayToRem,
    toastMessageClassName,
    toastMessageClassNames,
    buttonClassName,
    labelClassName,
    pxToRem,
    chooseDesignBoardModalSlotClassNames,
} from '@archipro-design/aria';

import { svgIconClassName } from '@archipro-design/icons';
import { hex2rgba } from '~/utils/colorHelper';

export const ChooseDesignBoardModalDesktop: StyleRule = ({ theme }) => ({
    [`& .${chooseDesignBoardModalSlotClassNames.content}`]: {
        color: theme.siteVariables.colors.charcoal[250],
    },
    [`& .${chooseDesignBoardModalItemsSlotClassNames.item}`]: {
        ':hover': {
            [`& .${chooseDesignBoardModalItemsSlotClassNames.save}`]: {
                [`& .${labelClassName}`]: {
                    color: theme.siteVariables.colors.charcoal[250],
                },
                ':hover': {
                    background:
                        theme.siteVariables.colors.transparency.black['6%'],
                },
            },
        },
    },
});

export const ChooseDesignBoardModalMobile: StyleRule = ({ theme }) => ({
    [`& div.${chooseDesignBoardModalSlotClassNames.header}`]: {
        minHeight: pxToRem(48),
        [`& .${labelClassName}`]: {
            fontSize: pxToRem(16),
            lineHeight: 1,
            letterSpacing: 0,
            ...theme.siteVariables.textStyles.Text.Roman55,
        },
        [`& .${buttonClassName}`]: {
            width: pxToRem(40),
            height: pxToRem(40),
        },
        [`& .${svgIconClassName}`]: {
            width: pxToRem(20),
            height: pxToRem(20),
        },
    },

    [`& .${chooseDesignBoardModalSlotClassNames.list}`]: {
        [`& .${chooseDesignBoardModalItemsSlotClassNames.item}`]: {
            [`& > .${flexClassName}`]: {
                minHeight: pxToRem(48),
                [`& > .${flexClassName}`]: {
                    width: pxToRem(288),
                },
            },

            [`& .${labelClassName}`]: {
                fontSize: pxToRem(16),
                lineHeight: 1.1,
                letterSpacing: 0,
                ...theme.siteVariables.textStyles.Text.Roman55,
            },
        },

        [`& .${chooseDesignBoardModalItemsSlotClassNames.title}`]: {
            [`& .${chooseDesignBoardModalItemsSlotClassNames.lock}`]: {
                width: pxToRem(16),
                height: pxToRem(16),
            },
            [`& .${labelClassName}`]: {
                flexGrow: 1,
                alignItems: 'center',
                display: 'flex',
            },
        },

        [`& .${chooseDesignBoardModalItemsSlotClassNames.save}`]: {
            width: pxToRem(72),
            height: pxToRem(40),
            marginRight: pxToRem(16),
            padding: 0,
            display: 'block',
            [`& .${labelClassName}`]: {
                color: theme.siteVariables.colors.charcoal[250],
            },
            background: theme.siteVariables.colors.transparency.black['6%'],
            ':disabled': {
                display: 'block',
                background: 'none',
            },
        },
    },

    [`& footer.${chooseDesignBoardModalSlotClassNames.footer}`]: {
        height: pxToRem(48),
        margin: pxArrayToRem([16, 18, 16, 18]),

        [`& .${buttonClassName}`]: {
            height: pxToRem(48),
            [`& .${labelClassName}`]: {
                fontSize: pxToRem(16),
                lineHeight: 1,
                letterSpacing: 0,
            },
            [`& .${svgIconClassName}`]: {
                marginTop: pxToRem(5),
                width: pxToRem(16),
                height: pxToRem(16),
            },
        },
    },
});

export const Toaster: StyleRule = ({ theme }) => ({
    [`&.${toastMessageClassName} .${toastMessageClassNames.container}`]: {
        backgroundColor: 'unset',
        boxShadow: 'unset',
        marginLeft: '0',
        zIndex: theme.siteVariables.zIndexes.overlay,
        width: pxToRem(386), // for mobile, don't change
        height: 'auto',

        [theme.screen.tablet]: {
            width: 'auto',
            minWidth: pxToRem(450),
            maxWidth: pxToRem(480),
        },
    },
    [`&.${toastMessageClassName} .${toastMessageClassNames.content}`]: {
        alignItems: 'flex-start',
        padding: pxToRem(16),

        backgroundColor: theme.siteVariables.colors.charcoal[250],
        boxShadow: `0px 14px 24px 2px ${hex2rgba(
            theme.siteVariables.colors.primitive.black,
            0.08
        )}`,
        transform: 'translateX(-50%)',
    },

    [`&.${toastMessageClassName} .${toastMessageClassNames.iconBefore}`]: {
        margin: pxArrayToRem([2, 8, 0, 0]),
    },

    [`&.${toastMessageClassName} .${toastMessageClassNames.message}`]: {
        padding: pxArrayToRem([4, 8, 4, 0]),
        letterSpacing: '0.01em',
        lineHeight: 1.35,
        [`& .${toastMessageClassNames.clickHere}`]: {
            whiteSpace: 'nowrap',
            padding: 0,
        },
    },

    [`&.${toastMessageClassName} .${toastMessageClassNames.closeButton} .${svgIconClassName}`]:
        {
            width: pxToRem(20),
            height: pxToRem(20),
        },
});
